import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { componentMap } from "../AllReportParameters";
import { ParameterOption } from "../types";

export const useAllParameters = (): ParameterOption[] => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  return useMemo(
    () =>
      Object.entries(componentMap).map(([key, value]) => ({
        label: t(key),
        value,
        key,
      })),
    [componentMap]
  );
};
