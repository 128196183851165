import { MultivalueCombobox, SelectItem } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type Props<T> = {
  label: string;
  selectedItems: SelectItem<T>[];
  onValueChange: (items: SelectItem<T>[]) => void;
  items: SelectItem<T>[];
  limit?: number;
};

export const TranslatedMultivalueCombobox = <T,>({
  label,
  selectedItems,
  onValueChange,
  items,
  limit,
}: Props<T>) => {
  const { t } = useTranslation("component", {
    keyPrefix: "MultivalueComboboxes",
  });

  const [itemsToShow, setItemsToShow] = useState<SelectItem<T>[]>(items ?? []);
  const [emptySetLabel, setEmptySetLabel] = useState<string | undefined>();

  const filterFunction = (query: string) => {
    const filterSet =
      query === ""
        ? selectedItems
        : selectedItems.filter(
            (item) =>
              item.label &&
              item.label
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(query.toLowerCase().replace(/\s+/g, ""))
          );
    setEmptySetLabel(filterSet.length === 0 ? t("noResults") : undefined);
    setItemsToShow(filterSet);
  };

  return (
    <MultivalueCombobox
      label={label}
      placeholder={label}
      selectedItems={selectedItems ?? []}
      onValueChange={onValueChange}
      multipleValuesText={t("multipleValues")}
      selectAllText={t("selectAll")}
      allSelectedText={t("all")}
      items={itemsToShow ?? []}
      filterHandler={filterFunction}
      limit={limit}
      disableSelectAll={limit !== undefined}
      emptySetLabel={emptySetLabel}
    />
  );
};
