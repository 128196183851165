import "@mescius/activereportsjs/pdfexport";
import "@mescius/activereportsjs/htmlexport";
import "@mescius/activereportsjs/tabulardataexport";
import "@mescius/activereportsjs/xlsxexport";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import "@mescius/activereportsjs/styles/ar-js-viewer.css";
import { useRef, useEffect } from "react";
import { Core } from "@mescius/activereportsjs";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { Viewer } from "@mescius/activereportsjs-react";
import { useSetTokens } from "./ReportHooks";
import { reportCustomCodes } from "../../SuperAdmin/ReportDesigner/CustomFunctions";

Core.CustomCode.registerFunctions(reportCustomCodes);

export const ReportViewer = ({
  report,
}: {
  report: ARJS.Report | undefined;
}) => {
  const viewerRef = useRef<Viewer>(null);

  const reportWithTokens = useSetTokens(report);

  const renderReport = () => {
    if (viewerRef.current) {
      viewerRef.current.Viewer.open(reportWithTokens, {});
    }
  };

  useEffect(() => {
    if (reportWithTokens) {
      renderReport();
    }
  }, [reportWithTokens]);

  return reportWithTokens && <Viewer theme="ActiveReports" ref={viewerRef} />;
};
