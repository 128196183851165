import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { getClientConfigVarOptional } from "../../../../../client/config";
import { getDataSourceDefinitions } from "../datasources";

const authHeader = `Header$Authorization=Bearer " & Parameters!token.Value`;
const dataSourceDefinitions = getDataSourceDefinitions();

const createDataSource = (
  baseUrl: string,
  name: string,
  url = ""
): ARJS.DataSource => ({
  Name: name,
  ConnectionProperties: {
    DataProvider: "JSON",
    ConnectString: `="endpoint=${baseUrl}${url};${authHeader}`,
  },
});

const createDataSets = (name: string): ARJS.DataSet[] => {
  const dataSourceDefinitions = getDataSourceDefinitions();
  const { dataSets } = dataSourceDefinitions[name];

  return dataSets
    ? dataSets.map(({ Fields, Query }) => ({
        Name: name,
        Fields,
        Query,
      }))
    : [];
};

export const getDataSources = (
  dataSourceNameList: string[]
): ARJS.DataSource[] => {
  const baseUrl = getClientConfigVarOptional("CLIENT_REST_API_URL");

  const dataSourcesWithUrl = dataSourceNameList.filter(
    (dataSourceName) => !!dataSourceDefinitions[dataSourceName].url
  );

  return dataSourcesWithUrl.map((dataSourceName) =>
    createDataSource(
      baseUrl,
      dataSourceName,
      dataSourceDefinitions[dataSourceName].url
    )
  );
};

export const getDataSourceDataSets = (
  dataSourceNameList: string[]
): ARJS.DataSet[] =>
  dataSourceNameList.map((name) => createDataSets(name)).flat();

export const getDataSetDependencies = (dataSets: ARJS.DataSet[]): string[] =>
  dataSets
    .map(({ Query }) => Query?.DataSourceName)
    .filter((d): d is string => !!d);

export const getDataSourceNames = (
  dataSources: undefined | ARJS.DataSource[]
): string[] =>
  dataSources
    ? dataSources.map(({ Name }) => Name).filter((n): n is string => !!n)
    : [];
