import { useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Loader, ToggleVisibilityButton, IconLtV1 } from "@tocoman/ui";
import { StringCombobox } from "../../../components/StringCombobox";

import {
  useGetReportTemplateNames,
  useGetReportTemplateByName,
} from "./ReportHooks";
import { ReportViewer } from "./ReportViewer";
import { ReportViewProps } from "./types";

export type ReportSelectionForm = {
  reportName?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Report = ({ projectId }: ReportViewProps) => {
  // TODO: use projectId in parameter implementation
  const { t } = useTranslation("reports");
  const methods = useForm<ReportSelectionForm>({
    defaultValues: {
      reportName: "C200",
    },
  });
  const targetRef = useRef<HTMLDivElement>(null);

  const { watch } = methods;

  const activeReportName = watch("reportName");

  const listResults = useGetReportTemplateNames();
  const reportResults = useGetReportTemplateByName(activeReportName);

  const isDataLoading = listResults.isLoading || reportResults.isLoading;

  return (
    <div className={"flex h-full w-full"}>
      <div className={"flex flex-row w-full"}>
        <div className={"flex flex-col gap-4 pr-3 w-auto"}>
          <div className="flex flex-row items-start gap-5 min-h-full max-h-full ml-5 overflow-y-auto">
            <div ref={targetRef}>
              <div className="flex flex-col gap-5 w-[250px] m-3 h-full">
                <FormProvider {...methods}>
                  {!isDataLoading && (
                    <Controller
                      name="reportName"
                      render={({ field: { onChange, value } }) => (
                        <StringCombobox
                          label={t("report")}
                          initialValue={value}
                          items={listResults.data || []}
                          onValueChange={onChange}
                        />
                      )}
                    />
                  )}
                </FormProvider>
              </div>
            </div>
            <div className="border-l border-l-light min-h-full ml-2">
              <ToggleVisibilityButton
                icon={IconLtV1}
                targetRef={targetRef}
                className="ml-[-12px] mt-3"
              />
            </div>
          </div>
        </div>
        <div className={"w-full h-full"}>
          {isDataLoading && <Loader testId={"basic-info-loader"} />}
          {!isDataLoading && reportResults.data && (
            <div className={"w-full h-full"}>
              <ReportViewer report={reportResults.data} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
