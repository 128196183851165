import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TranslatedMultivalueCombobox } from "../../../../components/TranslatedMultivalueCombobox";
import { useAllParameters } from "../hooks/useAllParameters";

export type ReportDataSet = {
  FilterExpression: string;
  Operator: string;
  FilterValues: string[];
  ParameterName: string;
};

export const DesignerParameterForm = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  const allParameters = useAllParameters();

  return (
    <>
      <Controller
        name={"parameters"}
        render={({ field: { onChange, value = [] } }) => (
          <TranslatedMultivalueCombobox
            label={t("parameters")}
            items={allParameters}
            onValueChange={onChange}
            selectedItems={value}
          />
        )}
      />
      <div className="flex flex-col gap-5 w-1/2 m-3 h-full">
        <div className="flex flex-col gap-3 m-3 pr-[12px] w-full h-full">
          <div className="flex flex-col gap-3 p-3 w-full h-full border-light rounded border"></div>
        </div>
      </div>
    </>
  );
};
