import "@mescius/activereportsjs/styles/ar-js-designer.css";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { Core } from "@mescius/activereportsjs";

import { reportCustomCodes } from "./CustomFunctions";
import { Loader } from "@tocoman/ui";
import { reportError } from "src/client-ts/utils/error";
import {
  useGetReportTemplateByName,
  useUpdateReportTemplate,
} from "./hooks/useReportTemplates";
import { ReportDesigner } from "./ReportDesigner";

Core.CustomCode.registerFunctions(reportCustomCodes);

export const ReportDesignerContainer = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "reportDesigner",
  });

  const { reportName, projectId } = useParams();

  useEffect(() => {
    if (!reportName) {
      reportError(t("reportNameMissing"), new Error(t("reportNameMissing")));
    }

    if (projectId === undefined || projectId === "null") {
      reportError(t("projectIdMissing"), new Error(t("reportNameMissing")));
    }
  }, [reportName, projectId]);

  const {
    data: reportTemplate,
    isLoading,
    isError,
    error,
  } = useGetReportTemplateByName(reportName);
  const saveReportTemplate = useUpdateReportTemplate();

  if (isError) {
    reportError(t("fetchFailed"), error as Error);
  }

  const updateReport = async (
    name: string,
    template: ARJS.Report
  ): Promise<number> => {
    const { id } = await saveReportTemplate.mutateAsync({
      name,
      template,
    });

    return id;
  };

  return (
    <div className="w-full h-full flex pl-5">
      {isLoading && <Loader />}
      {!isLoading && reportTemplate && projectId && (
        <ReportDesigner
          report={reportTemplate}
          projectId={projectId}
          updateReport={updateReport}
        />
      )}
    </div>
  );
};
