import { Designer, Viewer } from "@mescius/activereportsjs-react";
import * as ARJSD from "@mescius/activereportsjs/reportdesigner";
import { useEffect, useRef, useState } from "react";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ReportTemplate } from "@prisma/client";
import { Button, IconPencil, IconPlay } from "@tocoman/ui";
import { ReportDesignerSettings } from "./components/ReportDesignerSettings";
import {
  getDataSourceDataSets,
  getDataSources,
  getDataSetDependencies,
} from "./utils/dataSourceUtils";
import { ParameterOption } from "./types";
import { getAccessToken } from "src/client-ts/auth/accessToken";

export interface ReportDesignerContainerProps {
  report: ReportTemplate;
  projectId: string;
  updateReport: (name: string, template: ARJS.Report) => Promise<number>;
}

export const ReportDesigner = ({
  report,
  projectId,
  updateReport,
}: ReportDesignerContainerProps) => {
  const [activeTemplate, setActiveTemplate] = useState<ARJS.Report>({});
  const [editMode, setEditMode] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [templateEdited, setTemplateEdited] = useState(false);
  const viewerRef = useRef<Viewer>(null);
  const designerRef = useRef<Designer>(null);

  const getInitialParams = (token: string): ARJS.ReportParameter[] => [
    {
      Name: "projectId",
      DataType: "String",
      DefaultValue: {
        Values: [projectId],
      },
    },
    {
      Name: "token",
      DataType: "String",
      DefaultValue: {
        Values: [token],
      },
    },
  ];

  const setInitialTemplate = async (report: ARJS.Report) => {
    const token = await getAccessToken();
    const templateParameters = report.ReportParameters || [];
    const initialReportTemplate: ARJS.Report = {
      ...report,
      ReportParameters: [...templateParameters, ...getInitialParams(token)],
    };

    setActiveTemplate(initialReportTemplate);
  };

  useEffect(() => {
    if (report) {
      setInitialTemplate(report.template as ARJS.Report);
    }
  }, [report]);

  const renderReportInViewer = (report: ARJS.Report) => {
    if (viewerRef.current) {
      viewerRef.current.open(report);
    }
  };

  const loadReportFromDesigner = async () => {
    let result = null;

    if (designerRef.current) {
      const { definition } = await designerRef.current.getReport();
      result = definition;
    }

    return result;
  };

  const updateReportTemplate = (
    dataSourceNames: string[],
    parameters: ParameterOption[]
  ) => {
    setTemplateEdited(true);

    const newParameters: ARJS.ReportParameter[] = parameters.map(({ key }) => ({
      Name: key,
      DataType: "String",
    }));

    const activeReportParameters = activeTemplate.ReportParameters || [];
    const mergerdParameters: ARJS.ReportParameter[] = [
      ...activeReportParameters,
      ...newParameters,
    ];

    const dataSets = getDataSourceDataSets(dataSourceNames);

    // DataSets might be dependent on DataSources, which need the be added also
    const dependeeDataSourceNames = getDataSetDependencies(dataSets);

    // remove duplicates using `Set`
    const mergedDataSourceNames = [
      ...new Set([...dataSourceNames, ...dependeeDataSourceNames]),
    ];

    const dataSources = getDataSources(mergedDataSourceNames);

    setActiveTemplate({
      ...activeTemplate,
      ReportParameters: mergerdParameters,
      DataSources: dataSources,
      DataSets: dataSets,
    });
  };

  const goToEditMode = () => setEditMode(true);

  const goToViewMode = async () => {
    const report = await loadReportFromDesigner();
    if (report) {
      setActiveTemplate(report);
      setEditMode(false);
      renderReportInViewer(report);
    }
  };

  const onSave = async (
    info: ARJSD.SaveReportInfo
  ): Promise<ARJSD.SaveAsResult> => {
    const name = info.displayName ?? report.name;

    const id = updateReport(name, info.definition);

    setTemplateEdited(false);

    return {
      displayName: name,
      id: id.toString(),
    };
  };

  return (
    <>
      <div className="flex flex-row items-start gap-5 h-full">
        {report && (
          <ReportDesignerSettings
            report={activeTemplate}
            updateReportTemplate={updateReportTemplate}
          />
        )}
      </div>

      <div className="w-full h-full ml-[-10px] flex flex-col">
        {activeTemplate && (
          <>
            {editMode && (
              <>
                <div className="w-full h-full flex flex-col">
                  <div
                    id="designer-host"
                    style={{ display: editMode ? "block" : "none" }}
                  >
                    <Designer
                      report={{
                        id: report.name,
                        definition: activeTemplate,
                        displayName: report.name,
                      }}
                      ref={designerRef}
                      onSave={onSave}
                    />
                  </div>
                </div>
                <div className="flex flex-row z-100 gap-5 ml-[60px] mt-[-60px]">
                  <Button
                    onClick={() => goToViewMode()}
                    icon={IconPlay}
                    className="rounded-full text-xl w-[40px] h-[40px]"
                  />
                </div>
              </>
            )}
            {!editMode && (
              <>
                <div className="w-full h-full flex flex-col">
                  <Viewer theme="ActiveReports" ref={viewerRef} />
                </div>
                <div className="flex flex-row z-100 gap-5 ml-[60px] mt-[-60px]">
                  <Button
                    onClick={() => goToEditMode()}
                    icon={IconPencil}
                    className="rounded-full text-xl w-[40px] h-[40px]"
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
