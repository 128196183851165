import { ReportTemplate } from "@prisma/client";
import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useTranslation } from "react-i18next";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import axiosClient from "src/client-ts/axiosConfig";
import { getAccessToken } from "../../../auth/accessToken";
import { reportError } from "src/client-ts/utils/error";

const TOKEN_PLACEHOLER = "TOKEN";

const baseUrl = "/report-templates";

export const setDataSourceToken = (
  token: string,
  datasource: ARJS.DataSource
): ARJS.DataSource => {
  const datasourceWithToken = { ...datasource };
  const { ConnectionProperties } = datasource;

  if (ConnectionProperties && ConnectionProperties.ConnectString) {
    const connectStringWithToken = ConnectionProperties.ConnectString.replace(
      TOKEN_PLACEHOLER,
      token
    );

    datasourceWithToken.ConnectionProperties = {
      ...ConnectionProperties,
      ConnectString: connectStringWithToken,
    };
  }

  return datasourceWithToken;
};

const isJSONDataSource = ({ ConnectionProperties }: ARJS.DataSource): boolean =>
  ConnectionProperties !== undefined &&
  ConnectionProperties.DataProvider === "JSON";

const formatDataSource = (
  token: string,
  datasource: ARJS.DataSource
): ARJS.DataSource =>
  isJSONDataSource(datasource)
    ? setDataSourceToken(token, datasource)
    : datasource;

export const useSetTokens = (report: ARJS.Report | undefined): ARJS.Report => {
  const [data, setData] = useState({});

  useEffect(() => {
    async function getData() {
      if (report) {
        const token = await getAccessToken();

        let dataSourcesWithTokens: ARJS.DataSource[] = [];

        if (report.DataSources) {
          dataSourcesWithTokens = report.DataSources.map((dataSource) =>
            formatDataSource(token, dataSource)
          );
        }
        setData({ ...report, DataSources: dataSourcesWithTokens });
      }
    }

    getData();
  }, []);

  return data;
};

export function useGetReportTemplateNames(): UseQueryResult<string[]> {
  const { t } = useTranslation("reports");
  return useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const { data } = await axiosClient.get<ReportTemplate[]>(baseUrl);
      return data.map(({ name }) => name);
    },
    onError: (error) =>
      reportError(t("error.reportListTemplateFetch"), error as Error),
  });
}

export function useGetReportTemplateByName(
  name: string | undefined
): UseQueryResult<ARJS.Report> {
  const { t } = useTranslation("reports");
  return useQuery({
    queryKey: [baseUrl, name],
    enabled: !!name,
    queryFn: async () => {
      const { data } = await axiosClient.get<ReportTemplate>(
        `${baseUrl}/${name}/template`
      );
      return data;
    },
    onError: (error) =>
      reportError(t("error.reportTemplateFetch"), error as Error),
  });
}
