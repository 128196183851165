import { Rdl as ARJS } from "@mescius/activereportsjs/core";

export interface DataSetDefinition {
  Name: string;
  Query: ARJS.Query;
  CaseSensitivity?: ARJS.DataSet["CaseSensitivity"];
  KanatypeSensitivity?: ARJS.DataSet["KanatypeSensitivity"];
  AccentSensitivity?: ARJS.DataSet["AccentSensitivity"];
  WidthSensitivity?: ARJS.DataSet["WidthSensitivity"];
  Fields?: ARJS.Field[];
}

export interface DataSourceDefinition {
  url?: string;
  dataSets?: DataSetDefinition[];
}

export const getDataSourceDefinitions = (): Record<
  string,
  DataSourceDefinition
> => ({
  Projects: {
    url: `/api/v1/estima/projects/`,
    dataSets: [
      {
        Name: "Projects",
        Query: {
          DataSourceName: "Projects",
          CommandText: "uri=/;jpath=$.results[*]",
        },
      },
    ],
  },
  Groups: {
    dataSets: [
      {
        Name: "Groups",
        Query: {
          DataSourceName: "Projects",
          CommandText:
            '="uri=/" & Parameters!projectId.Value & "/groups;jpath=$.results[*]"',
        },
      },
    ],
  },
  Locations: {
    dataSets: [
      {
        Name: "Locations",
        Query: {
          DataSourceName: "Projects",
          CommandText:
            '="uri=/" & Parameters!projectId.Value & "/locations;jpath=$.results[*]"',
        },
      },
    ],
  },
  Classifications: {
    dataSets: [
      {
        Name: "Classifications",
        Query: {
          DataSourceName: "Projects",
          CommandText:
            '="uri=/" & Parameters!projectId.Value & "/classifications;jpath=$.results[*]"',
        },
      },
    ],
  },
  WorkItems: {
    dataSets: [
      {
        Name: "WorkItems",
        Query: {
          DataSourceName: "Projects",
          CommandText:
            '="uri=/" & Parameters!projectId.Value & "/work-items?include=calculations&include=locations;jpath=$.results[*]"',
        },
      },
    ],
  },
  Resources: {
    dataSets: [
      {
        Name: "Resources",
        Query: {
          DataSourceName: "Projects",
          CommandText:
            '="uri=/" & Parameters!projectId.Value & "/resources?include=calculations;jpath=$.results[*]"',
        },
      },
    ],
  },
});
