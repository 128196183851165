import { useEffect, useRef } from "react";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { DataSourceSelector } from "../components/DataSourceSelector";
import { DesignerParameterForm } from "./ParameterSelector";
import { AllReportParametersData } from "../AllReportParameters";
import { Button, ToggleVisibilityButton, IconLtV1, Header } from "@tocoman/ui";
import { ParameterOption } from "../types";
import { useAllParameters } from "../hooks/useAllParameters";
import { getDataSourceNames } from "../utils/dataSourceUtils";
import { getDataSourceDefinitions } from "../datasources";

export type ReportDesignerForm = {
  projectId?: number;
  dataSources?: string[];
  parameters?: ParameterOption[];
} & AllReportParametersData;

export type ReportDesignerSettingsProps = {
  report: ARJS.Report;
  updateReportTemplate: (
    dataSourceNameList: string[],
    parameters: ParameterOption[]
  ) => void;
};

export const ReportDesignerSettings = ({
  report,
  updateReportTemplate,
}: ReportDesignerSettingsProps) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  const allParameters = useAllParameters();
  const methods = useForm<ReportDesignerForm>({
    defaultValues: {},
  });
  const { handleSubmit, reset } = methods;

  const dataSourceDefinitioNames = Object.keys(getDataSourceDefinitions());

  useEffect(() => {
    // Use only dataSources, which exists in the data source definitions. Removes custom ones
    const activeDataSources = getDataSourceNames(
      report.DataSources
    ).filter((ds) => dataSourceDefinitioNames.some((d) => ds === d));

    const activeParameters = report.ReportParameters
      ? allParameters.filter(({ key }) =>
          report.ReportParameters?.some(({ Name }) => Name === key)
        )
      : [];

    reset({
      dataSources: activeDataSources,
      parameters: activeParameters,
    });
  }, [report]);

  const targetRef = useRef<HTMLDivElement | null>(null);

  const updateNewParameters = (parameters: ParameterOption[]) => {
    const isSet = ({ key }: ParameterOption) =>
      report.ReportParameters?.some(({ Name }) => key === Name);

    return parameters.filter((parameter) => !isSet(parameter));
  };

  const handlePreviewSubmit = (data: ReportDesignerForm) => {
    const newDataSources = data.dataSources || [];
    const newParameters = data.parameters
      ? updateNewParameters(data.parameters)
      : [];

    updateReportTemplate(newDataSources, newParameters);
  };

  return (
    <div className="flex w-full h-full">
      <div
        className={"min-h-full max-h-full h-full overflow-y-auto"}
        ref={targetRef}
      >
        <div className="flex flex-row gap-3 w-[600px] p-3 min-h-full">
          <FormProvider {...methods}>
            <div className="flex flex-col gap-3 w-1/2 min-h-full pt-3">
              <Header titleSize={"small"} title={report.Name} />
              <DataSourceSelector />

              <DesignerParameterForm />

              <Button
                className="w-48 mt-3"
                onClick={handleSubmit((d) => handlePreviewSubmit(d))}
                label={t("setSettings")}
              />
            </div>
          </FormProvider>
        </div>
      </div>
      <div className={"border-l border-l-light h-full z-50"}>
        <ToggleVisibilityButton
          icon={IconLtV1}
          targetRef={targetRef}
          className={"ml-[-12px] mt-3"}
          onClick={handleSubmit((d) => handlePreviewSubmit(d))}
        />
      </div>
    </div>
  );
};
